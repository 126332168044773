<div class="box">
  <title-dialog [title]="data.title || 'ARCHIVOS Y COMENTARIOS'" [color]="'greendAnalityco'"  [width]="'360'"></title-dialog>

  <div *ngIf="!isProcessing" class="content-card">
    <div class="info-box">
      <p><strong>Pasos:</strong></p>
      <p>1. Descargue el archivo con el formato</p>
      <p>2. Diligencie los datos en el archivo descargado</p>
      <p>3. Cargue el archivo</p>
      <p><strong>Nota:</strong> En cada carga solo se permiten 100 registros</p>
    </div>

    <div class="content-icono">
      <p class="download-text"><strong>Formato para descargar</strong></p>
      <div class="format-download">
        <a [href]="urlFormat" download>
          <mat-icon class="download-icon">
            <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 -960 960 960" width="36px" fill="#5f6368">
              <path d="m720-120 160-160-56-56-64 64v-167h-80v167l-64-64-56 56 160 160ZM560 0v-80h320V0H560ZM240-160q-33 0-56.5-23.5T160-240v-560q0-33 23.5-56.5T240-880h280l240 240v121h-80v-81H480v-200H240v560h240v80H240Zm0-80v-560 560Z"/>
            </svg>
          </mat-icon>
        </a>
      </div>
    </div>

    <div class="content-show" style="padding-left: 12px; padding-right: 12px;">
      <div class="subtitle">ACTUALIZAR</div>
      <div *ngIf="data.isFromPersonal">
        <ngx-dropzone  *ngIf="files.length === 0" (change)="onSelect($event)" class="dropzone" [multiple]="false">
          <ngx-dropzone-label>Clic aquí ó arrastra y suelta un archivo</ngx-dropzone-label>
        </ngx-dropzone>
      </div>

      <div *ngIf="data.isFromEmployer">
        <ngx-dropzone  *ngIf="files.length === 0" (change)="onSelect($event)" class="dropzone" [multiple]="false">
          <ngx-dropzone-label>Clic aquí ó arrastra y suelta un archivo</ngx-dropzone-label>
        </ngx-dropzone>
      </div>

      <!-- Sección para mostrar el archivo subido -->
      <div *ngIf="files.length > 0" class="uploaded-file">
        <span class="file-name">{{ files[0]?.name }}</span>
        <button mat-icon-button class="remove-button" (click)="removeFile()">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>


      <div *ngIf="data.isFromPersonal">
        <mat-form-field class="input-field">
          <label><div>COMENTARIO</div></label>
          <textarea matInput [(ngModel)]="commentary" maxlength="280" rows="5"></textarea>
          <mat-error>Requerido</mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="data.isFromEmployer">
        <mat-form-field class="input-field">
          <label><div>COMENTARIO</div></label>
          <textarea matInput [(ngModel)]="commentary" maxlength="280" rows="5"></textarea>
          <mat-error>Requerido</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="actions-dialog border-bottom-actions" style="padding: 0px 12px 8px 12px;" *ngIf="!data.typeGestor">
      <button mat-button class="btn-cancelar" (click)="onNoClick()" >{{(data.typeOperator === 'show')? 'CERRAR': 'CANCELAR'}}</button>&nbsp;&nbsp;
      <button mat-button class="btn-aceptar" *ngIf="files.length !== 0" (click)="submitInitDocument()">INICIAR</button>
    </div>

    <div *ngIf="storiesFiles.length === 0">
      <div class="document-content">
       <label>0 DOCUMENTOS GUARDADOS</label>
      </div>
    </div>

    <div>
      <div *ngIf="storiesFiles.length > 0">
      <div class="subtitleAc">ACTUALIZACIONES</div>
      <div class="history-scroll" style="width: 100%; padding: 0px 0px 0px 12px;">
        <div class="history" *ngFor="let story of storiesFiles; let i = index">
          <div class="state" style="color: #a0a0a0 !important;">
            <div class="state-content">
              <div class="itemState">{{story.StateDescription}} Actualizado:</div>
              <div class="itemState">{{getDateFormatR(story?.Date) || 'Fecha no reportada' }} {{getDateFormatRMinutes(story?.Date) || '' }} </div>
              <div class="itemStateIcon"> <div>Por:</div> <mat-icon matTooltipPosition="above" [matTooltip]="'Usuario: ' + (story?.UserName !== undefined ? story?.UserName : 'No reportado')">account_circle</mat-icon></div>
            </div>
            <div class="state-document" *ngIf="story?.Key !== '' && story?.Key !== null">
              <div class="itemState"><div>Documento:</div></div>
              <div class="itemState-nameFile" matTooltipPosition="above" [matTooltip]="stateCurrent.Name">{{story.Name}}</div>
              <div class="itemStateIcon"><mat-icon class="iconView" (click)="openImgVideoUrl(story.Key)">visibility</mat-icon></div>
            </div>
          </div>
        </div>

      </div>
      <app-title-card-preview
        *ngIf="storiesFiles.length === 0"
        style="width: 100%;"
        [icon]="'help'"
        [text]="'Aqui se visualiza el historial'"
        [textTooltip]="'Aqui se visualiza el historial'"
      ></app-title-card-preview>
      </div>
    </div>
  </div>

  <div *ngIf="isProcessing" class="content-processing">
    <label class="processing-int">PROCESANDO</label>
    <mat-progress-bar mode="query" [value]="20" *ngIf="true"></mat-progress-bar>
  </div>

</div>
