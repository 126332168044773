import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NumberCountry } from 'src/app/core/constants/number-city.constant';
import { TypeSecurityReference } from 'src/app/core/constants/type-security-references';
import { City, CommonOptions, DaneCity, SecurityReference } from 'src/app/core/interfaces/interfaces';
import { CommonService } from 'src/app/core/services/modules/common.service';
import { EmployerService } from 'src/app/core/services/modules/employer.service';
import { PersonalService } from 'src/app/core/services/modules/personal.service';
import { DialogService } from 'src/app/core/services/utils/dialog.service';
import { FechasService } from 'src/app/core/services/utils/fechas.service';
import { SnackBarService } from 'src/app/core/services/utils/snackBar.service';

export interface DialogData {
  StartDate: string, FinishDate: string, id: any, CompanyInfoId: any, DocumentNumber?: string, DocumentType?: string, IsRequest: boolean
}

@Component({
  selector: 'app-dialog-create-person-info-company',
  templateUrl: './dialog-create-person-info-company.component.html',
  styleUrls: ['./dialog-create-person-info-company.component.scss']
})
export class DialogCreatePersonInfoCompanyComponent implements OnInit {

  formPersonal: FormGroup;


  nationality :CommonOptions[] = [];
  rh :CommonOptions[] = [];
  companyDocumentType :CommonOptions[] = [];
  sex :CommonOptions[] = [];
  countrys :CommonOptions[] = [];
  cities: City[] = [];
  citiesNacimiento: City[] = [];
  citiesRecidence: City[] = [];
  citiesDefault: City[] = [];
  jobs: any[] = [];
  daneCity: DaneCity[] = [];

  maxCaracter: number = 11;
  formDataPhoto: FormData;

  afp: SecurityReference[] = [];
  eps: SecurityReference[] = [];
  securityReferences: any = [];

  imgCropperResult: string;

  constructor(
    public dialogRef: MatDialogRef<DialogCreatePersonInfoCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private commonService: CommonService,
    private snackBService: SnackBarService,
    private fechasService: FechasService,
    private dialogService: DialogService,
    private employerService: EmployerService,
    private personalService: PersonalService,
  ) { 
  }


// Address: "Karre 5B-38 Sur"
// AfpId: 80
// BirthDate: "19950411"
// BirthDateSelect: "1995-04-11T05:00:00.000Z"
// CityBirthCode: "001001"
// CityCode: "17001"
// CompanyInfoId: "f3dfc3f6-7da3-423b-bb55-5631f6517d31"
// DocumentNumber: "1023944562"
// DocumentType: "CC"
// EmergencyContact: "Andres"
// EmergencyContactPhone: 21213123132
// EpsId: 79
// JobCode: "D-0000.000"
// LastName: "OCAMPO"
// Name: "SAMUEL"
// Nationality: 0
// PhoneNumber: 5412325699
// RH: "O+"
// Sex: "M"

// AfpId: 62
// ArlId: 81
// ContractId: "041e61bc-3327-4f8e-aeaa-5f1316eaff22"
// EmployerId: null
// EpsId: 11
// FinishDate: "2021-06-30T05:00:00.000Z"
// NameARL: "ARL DESCONOCIDA"
// PersonalCompanyInfoId: 15406
// Position: "cargo"
// StartDate: "2021-06-09T05:00:00.000Z"
// TypeARL: "V"

  ngOnInit(): void {
    this.servicesPersonal();
    this.formPersonal = this.fb.group({
        Address: new FormControl(''),
        AfpId: new FormControl(''),
        BirthDate: new FormControl('',Validators.required),
        BirthDateSelect: new FormControl(''),
        CityBirthCode: new FormControl('',Validators.required),
        contryNalcionality: new FormControl('',Validators.required),
        CityCode: new FormControl(''),
        CompanyInfoId: new FormControl(this.data.CompanyInfoId,Validators.required),
        DocumentNumber: new FormControl({value: '',disabled: true},Validators.required),
        DocumentType: new FormControl({value: '',disabled: true},Validators.required),
        EmergencyContact: new FormControl(''),
        EmergencyContactPhone: new FormControl(''),
        EpsId: new FormControl(''),
        JobCode: new FormControl(''),
        LastName: new FormControl('',Validators.required),
        Name: new FormControl('',Validators.required),
        Nationality: new FormControl('',Validators.required),
        PhoneNumber: new FormControl(''),
        RH: new FormControl('',Validators.required),
        Sex: new FormControl('',Validators.required),
        Email: new FormControl(''),
    });
    if(this.data.DocumentNumber) {
      this.formPersonal.get('DocumentType').setValue(this.data.DocumentType);
      this.formPersonal.get('DocumentNumber').setValue(this.data.DocumentNumber);
      if(this.formPersonal.get('DocumentType').value) {
        this.formPersonal.get('Nationality').setValue('0');  
      }
    }
  }

  onSubmit() {
    if(this.formDataPhoto === undefined) {
      this.snackBService.openSnackBar('Por favor subir imagen de la persona', 'X', 4000);
    }else if(this.formPersonal.status === 'VALID') {
      let personal = {
        Address: this.formPersonal.get('Address').value,
        AfpId: this.formPersonal.get('AfpId').value,
        BirthDate: this.fechasService.getDateFormatDayMontYear(this.formPersonal.get('BirthDate').value),
        BirthDateSelect: this.formPersonal.get('BirthDate').value,
        CityBirthCode: this.formPersonal.get('CityBirthCode').value,
        contryNalcionality: this.formPersonal.get('contryNalcionality').value,
        CityCode: this.formPersonal.get('CityCode').value,
        CompanyInfoId: this.formPersonal.get('CompanyInfoId').value,
        DocumentNumber: this.formPersonal.get('DocumentNumber').value,
        DocumentType: this.formPersonal.get('DocumentType').value,
        EmergencyContact: this.formPersonal.get('EmergencyContact').value,
        EmergencyContactPhone: this.formPersonal.get('EmergencyContactPhone').value,
        EpsId: this.formPersonal.get('EpsId').value,
        JobCode: "D-0000.000",
        LastName: this.formPersonal.get('LastName').value,
        Name: this.formPersonal.get('Name').value,
        Nationality: this.formPersonal.get('Nationality').value,
        PhoneNumber: this.formPersonal.get('PhoneNumber').value,
        RH: this.formPersonal.get('RH').value,
        Sex: this.formPersonal.get('Sex').value,
        Email: this.formPersonal.get('Email').value
      }
      
        this.personalService.postPersonalInfoCompany(personal).subscribe((restPostPersonal: any) => {
          let pesonalCompany = {
            AfpId: restPostPersonal.AfpId,
            ArlId: 81,
            ContractId: this.data.id,
            EmployerId: null,
            EpsId: restPostPersonal.EpsId,
            FinishDate: this.data.IsRequest === false ?this.data.FinishDate: this.fechasService.getDateFormatDatePikerDATEHORANOWNotSecunt(),
            NameARL: "ARL DESCONOCIDA",
            PersonalCompanyInfoId: restPostPersonal.PersonalCompanyInfoId,
            Position: "Función Desconocida",
            // StartDate: this.data.IsRequest === false ? this.data.StartDate: this.fechasService.getDateFormatDatePikerDATEHORANOWNotSecunt(),
            StartDate: this.fechasService.getDateFormatDatePikerDATEHORANOWNotSecunt(), 
            TypeARL: "V"
          }
          this.personalService.updatePhotoCompanyInfo(restPostPersonal.Id, this.formDataPhoto).subscribe((rest: any) =>{
              this.personalService.postContractPersonalInfo(this.data.id, pesonalCompany, this.data.IsRequest === false? undefined: true).subscribe(restContractPersonal => {
                  this.dialogRef.close({create: true, modelResponse: restContractPersonal, IsRequest: this.data.IsRequest});
              });
          });
        });
      
    } else {
      this.snackBService.openSnackBar('Completar el formulario', '', 3000);
    }
  }

  updatePhotoPersonal() {
    this.dialogService.openDialogGestorImg('CARGAR IMAGEN',[],'editPhoto', 0, 'true', true).afterClosed().subscribe(formDataPhoto =>{
      if(formDataPhoto !== undefined) {
        this.imgCropperResult = formDataPhoto.imgResult;
        this.formDataPhoto = formDataPhoto.FormData;
      }
      // rest !== undefined ? this.formDataPhoto = rest : this.formDataPhoto = this.formDataPhoto;
    });
  }

  servicesPersonal() {
    this.commonService.getCommonOptions("Nationality").subscribe((data: CommonOptions[]) => {
      this.nationality = data;
    });
    this.commonService.getCommonOptions("RH").subscribe((data: CommonOptions[]) => {
      this.rh = data.sort((a,b) => a.OrderNum > b.OrderNum ? 1 : -1);;
    });
    this.commonService.getCommonOptions("CompanyDocumentType").subscribe((data: CommonOptions[]) => {
      this.companyDocumentType = data.filter(documents => documents.Value === 'CC' || documents.Value === 'CE' || documents.Value === 'PE' || documents.Value === 'PPT')
          .sort((a,b) => a.OrderNum > b.OrderNum ? 1 : -1);
    });
    this.commonService.getCommonOptions("Sex").subscribe((data: CommonOptions[]) => {
      this.sex = data.sort((a,b) => a.OrderNum > b.OrderNum ? 1 : -1);
    });
    this.commonService.getCommonOptions("Country").subscribe((data: CommonOptions[]) => {
      this.countrys = data;
    });
    this.commonService.getJobs().subscribe((data: any) => {
      this.jobs = data;
    });
    this.employerService.getSecurityReferences().subscribe(data => {
      this.securityReferences = data;
      this.eps = this.securityReferences.filter(x => x.Type === TypeSecurityReference.EPS).sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
      this.afp = this.securityReferences.filter(x => x.Type === TypeSecurityReference.AFP).sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);
      // this.arl = this.securityReferences.filter(x => x.Type === TypeSecurityReference.ARL).sort((a, b) => a.OrderNum > b.OrderNum ? 1 : -1);

      // Se comenta porque no se puede terner eps afp desconocida para requerirla
      // this.formPersonal.get('EpsId').setValue(79);
      // this.formPersonal.get('AfpId').setValue(80);
    });
    this.getDaneCity();
    
  }

  getDaneCity() {
    this.commonService.getDaneCity().subscribe((daneCity: DaneCity[]) => {
      this.daneCity = daneCity;
    });
  }

  getCitiesNacimiento(numberContry: NumberCountry){
    this.commonService.getCities(numberContry).subscribe((data: City[]) => {
      this.citiesNacimiento = data;
    });
  }

  changeType(e) {
    if(e === 'PE') {
      this.maxCaracter = 16;
    } else if(e === 'CC') {
      this.maxCaracter = 11;
    } else {
      this.maxCaracter = 20;
    }
  }

  close() {
    this.dialogRef.close();
  }

}
