import { Component, OnInit, Inject } from '@angular/core';
import { DialogContratoIndividualComponent } from '../dialog-contrato-individual/dialog-contrato-individual.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from '../../../services/modules/common.service';
import { CommonOptions, DaneCity } from '../../../interfaces/interfaces';
import { City } from 'src/app/core/interfaces/interfaces';
import { environment } from 'src/environments/environment';
import { NumberCountry } from '../../../constants/number-city.constant';
import { ContratingService } from '../../../services/modules/contrating.service';
import { SnackBarService } from '../../../services/utils/snackBar.service';
import { PersonalModel } from '../../../models/Personal/personalModel';
import { PersonalEmployerInfo } from '../../../models/Personal/personalEmployerInfo';
import { FechasService } from 'src/app/core/services/utils/fechas.service';
import { DialogService } from '../../../services/utils/dialog.service';

export interface DialogData {
  data: any;
  DocumentType: string;
  DocumentNumber: string;
  personal: any;
}

@Component({
  selector: 'app-dialog-create-personal',
  templateUrl: './dialog-create-personal.component.html',
  styleUrls: ['./dialog-create-personal.component.scss']
})
export class DialogCreatePersonalComponent implements OnInit {
  s3Bucket:string;

  formPersonal: FormGroup;
  personal: PersonalModel;
  personalEmployerInfo: PersonalEmployerInfo;

  nationality :CommonOptions[] = [];
  rh :CommonOptions[] = [];
  companyDocumentType :CommonOptions[] = [];
  sex :CommonOptions[] = [];
  countrys :CommonOptions[] = [];
  cities: City[] = [];
  citiesNacimiento: City[] = [];
  citiesRecidence: City[] = [];
  citiesDefault: City[] = [];
  town: any[] = [];

  disablesForm: boolean = false;
  formDataPhoto: FormData;

  daneCity: DaneCity[] = [];

  maxCaracter: number = 11;

  
  constructor(
    public dialogRef: MatDialogRef<DialogCreatePersonalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private commonService: CommonService,
    private contratingService: ContratingService,
    private snackBService: SnackBarService,
    private fechasService: FechasService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    setTimeout(() => this.servicesPersonal(), 300); 
    this.s3Bucket=environment.api.s3Bucket;
    this.formPersonal = this.fb.group({
      Nationality: new FormControl('',Validators.required),
      DocumentNumber: new FormControl('',Validators.required),
      DocumentType: new FormControl('',Validators.required),
      Name: new FormControl('',Validators.required),
      LastName: new FormControl('',Validators.required),
      BirthDate: new FormControl('',Validators.required),
      Sex: new FormControl('',Validators.required),
      RH: new FormControl('',Validators.required),
      contryNalcionality: new FormControl('',Validators.required),
      CityCode: new FormControl('',Validators.required),
      CityOfBirthCode: new FormControl('',Validators.required),
      EmergencyContact: new FormControl(''),
      EmergencyContactPhone: new FormControl(''),
      Address: new FormControl('',Validators.required),
      PhoneNumber: new FormControl('',Validators.required),
      Email: new FormControl('',[Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      TownCode: ''
    });
    // console.log('this.data.DocumentNumber :>> ', this.data);
    if(this.data.DocumentNumber) {
      this.formPersonal.get('DocumentType').setValue(this.data.DocumentType);
      this.formPersonal.get('DocumentNumber').setValue(this.data.DocumentNumber);
      if(this.formPersonal.get('DocumentType').value) {
        this.formPersonal.get('Nationality').setValue('0');  
      }
    }
    if(this.data.personal){
      console.log('this.data',this.data);
      this.formPersonal.get('Nationality').setValue(this.data.personal[0].Nationality.toString());
      this.formPersonal.get('BirthDate').setValue(this.fechasService.formatBirthDate(this.data.personal[0].BirthDate));
      // console.log(this.fechasService.formatBirthDate(this.data.personal[0].BirthDate));
      this.formPersonal.get('CityOfBirthCode').setValue(this.data.personal[0].CityOfBirthCode);
      this.formPersonal.get('contryNalcionality').setValue(this.data.personal[0].CityOfBirthCode.substring(0, 1));
      this.getCitiesNacimiento(this.data.personal[0].CityOfBirthCode.substring(0, 1));
      this.formPersonal.get('LastName').setValue(this.data.personal[0].LastName);
      this.formPersonal.get('Name').setValue(this.data.personal[0].Name);
      this.formPersonal.get('RH').setValue(this.data.personal[0].RH);
      this.formPersonal.get('Sex').setValue(this.data.personal[0].Sex);
    } else {
      this.getCitiesNacimiento('0');
    }
   
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  formDataPoliticas = new FormData();
  _filePoliticas: File = null;
  changeFilePiliticas(files: FileList) {
    this._filePoliticas = files.item(0);
    this.formDataPoliticas.append('File', this._filePoliticas);
  }

  servicesPersonal() {
    this.commonService.getCommonOptions("Nationality").subscribe((data: CommonOptions[]) => {
      this.nationality = data;
    });
    this.commonService.getCommonOptions("RH").subscribe((data: CommonOptions[]) => {
      this.rh = data.sort((a,b) => a.OrderNum > b.OrderNum ? 1 : -1);;
    });
    this.commonService.getCommonOptions("CompanyDocumentType").subscribe((data: CommonOptions[]) => {
      this.companyDocumentType = data.filter(documents => documents.Value === 'CC' || documents.Value === 'CE' || documents.Value === 'PE')
          .sort((a,b) => a.OrderNum > b.OrderNum ? 1 : -1);
    });
    this.commonService.getCommonOptions("Sex").subscribe((data: CommonOptions[]) => {
      this.sex = data.sort((a,b) => a.OrderNum > b.OrderNum ? 1 : -1);
    });
    this.commonService.getCommonOptions("Country").subscribe((data: CommonOptions[]) => {
      this.countrys = data;
    });
    this.getDaneCity();
  }

  updatePhotoPersonal() {
    this.dialogService.openDialogGestorImg('CARGAR IMAGEN', [],'editPhoto', 0, 'true', true).afterClosed().subscribe(formDataPhoto =>{
      if(formDataPhoto !== undefined) {
        this.formDataPhoto = formDataPhoto.FormData;
      }
      // rest !== undefined ? this.formDataPhoto = rest : this.formDataPhoto = this.formDataPhoto;
    });
  }

  getCitiesNacimiento(numberContry: NumberCountry){
    this.commonService.getCities(numberContry).subscribe((data: City[]) => {
      this.citiesNacimiento = data;
    });
  }

  getCitiesRecidence(numberContry: NumberCountry){
    this.commonService.getCities(numberContry).subscribe((data: City[]) => {
      this.citiesRecidence = data;
    });
  }

  getCitiesDefault(numberContry: NumberCountry) {
    this.commonService.getCities(numberContry).subscribe((data: City[]) => {
      this.citiesDefault = data;
    });
  }

  getTown(idCity) {
    this.contratingService.getTownIdCity(idCity).subscribe((data: any) => {
      this.town = data;
    });
  } 

  getDaneCity() {
    this.commonService.getDaneCity().subscribe((daneCity: DaneCity[]) => {
      this.daneCity = daneCity;
    });
  }

  onSubmit() {
    // console.log(this.formPersonal);
    if(this.formDataPhoto === undefined) {
      this.snackBService.openSnackBar('Por favor subir imagen de la persona', 'X', 4000);
    }else if(this.formPersonal.status === "VALID") {
      this.personal = {
        DocumentType: this.formPersonal.get('DocumentType').value,
        DocumentNumber: this.formPersonal.get('DocumentNumber').value,
        Name: this.formPersonal.get('Name').value,
        BirthDate: this.fechasService.getDateFormatDayMontYear(this.formPersonal.get('BirthDate').value),
        LastName: this.formPersonal.get('LastName').value,
        RH: this.formPersonal.get('RH').value,
        Sex: this.formPersonal.get('Sex').value,
        Nationality: this.formPersonal.get('Nationality').value,
        CityOfBirthCode: this.formPersonal.get('CityOfBirthCode').value
      }
      this.personalEmployerInfo = {
        PersonalId: '',
        EmployerId: this.data.data,
        CityCode: this.formPersonal.get('CityCode').value,
        TownCode: this.formPersonal.get('TownCode').value,
        Address: this.formPersonal.get('Address').value,
        PhoneNumber: this.formPersonal.get('PhoneNumber').value,
        EmergencyContact: this.formPersonal.get('EmergencyContact').value,
        EmergencyPhone: this.formPersonal.get('EmergencyContactPhone').value,
        Email: this.formPersonal.get('Email').value
      }
      if(this.data.personal){
        this.personalEmployerInfo.PersonalId = this.data.personal[0].PersonalId;
          this.contratingService.postPersonalEmployerInfo(this.personalEmployerInfo).subscribe((persEmpleoyerInfo: any) => {
            if(persEmpleoyerInfo !== undefined) {
              this.contratingService.updatePhotoPersonal(persEmpleoyerInfo.Id, this.formDataPhoto).subscribe((rest: any) =>{
                let response = {Id: persEmpleoyerInfo.Id, Photo: rest.Photo, persEmpleoyerInfo: persEmpleoyerInfo}
                this.dialogRef.close(response);
              });
            }
          });
      } else {
        this.contratingService.postPersonal(this.personal).subscribe((personal: PersonalModel) => {
          if(personal) {
            this.personalEmployerInfo.PersonalId = personal.Id;
            this.contratingService.postPersonalEmployerInfo(this.personalEmployerInfo).subscribe((persEmpleoyerInfo: any) => {
              if(persEmpleoyerInfo !== undefined) {
                this.contratingService.updatePhotoPersonal(persEmpleoyerInfo.Id, this.formDataPhoto).subscribe((rest: any) =>{
                  let response = {Id: persEmpleoyerInfo.Id, Photo: rest.Photo, persEmpleoyerInfo: persEmpleoyerInfo}
                  this.dialogRef.close(response);
                });
              }
            });
          }
        });
      }
    } else {
      this.snackBService.openSnackBar('Completar los campos requeridos', 'X', 4000);
    }
    
  }

  changeType(e) {
    if(e === 'PE') {
      this.maxCaracter = 16;
    } else if(e === 'CC') {
      this.maxCaracter = 11;
    } else {
      this.maxCaracter = 20;
    }
  }

}
