import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../../models/User';
import {CommonOptions} from '../../../interfaces/interfaces';
import {FechasService} from '../../../services/utils/fechas.service';
import {CropperComponent} from 'angular-cropperjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AuthService} from '../../../services/modules/auth.service';
import {EmployerService} from '../../../services/modules/employer.service';
import {CommonService} from '../../../services/modules/common.service';
import {SnackBarService} from '../../../services/utils/snackBar.service';
import {environment} from '../../../../../environments/environment';
import {DialogService} from '../../../services/utils/dialog.service';
import {PersonalService} from '../../../services/modules/personal.service';
import {HttpErrorResponse} from '@angular/common/http';

export interface DialogData {
  title: string;
  docs: any;
  typeOperator: string;
  typeGestor: string;
  module: string;
  adminInfoId: string;
  isFromPersonal: boolean;
  isFromEmployer: boolean;
}

@Component({
  selector: 'app-dialog-carge-masivo',
  templateUrl: './dialog-carge-masivo.component.html',
  styleUrls: ['./dialog-carge-masivo.component.scss']
})
export class DialogCargeMasivoComponent implements OnInit {
  @ViewChild('angularCropper') angularCropper: CropperComponent;

  s3Bucket: string;
  imgCropperResult: string;
  DocsJSON: any = {};

  isProcessing = false;
  stories: any[] = [];
  storiesFiles: any[] = [];
  documentos: any[] = [];
  cropper = false;
  formData = new FormData();
  // FIles
  _file: File = null;
  formFile: FormGroup;
  files: File[] = [];
  email = '';
  commentary = '';
  permissions: string[] = [];

  userAuth: User;
  proveedor: CommonOptions[] = [];
  lastStages: CommonOptions[] = [];
  state;
  stateString = '';
  stateCurrent;
  module = '';
  permissionsCompany: string[] = [];

  date = new Date();
  minDate = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());

  urlFormat = '';

  permissionsGestor: any[] = [
    { module: 'Personal',
      permissions: { uploadFile: 'documentinfopersonal.updatedate', upload: 'documentinfopersonal.upload', requestapproval: 'documentinfopersonal.requestapproval', approval: 'documentinfopersonal.approval'}
    },
    { module: 'Employer',
      permissions: { uploadFile: 'documentinfoemployer.updatedate', upload: 'documentinfoemployer.upload', requestapproval: 'documentinfoemployer.requestapproval', approval: 'documentinfoemployer.approval'}
    },
    { module: 'SocialSecurity',
      permissions: { uploadFile: 'documentinfosocialsecurity.uploadFile', upload: 'documentinfosocialsecurity.upload', requestapproval: 'documentinfosocialsecurity.requestapproval', approval: 'documentinfosocialsecurity.approval'}
    }];
  permissionsModule: any;

  constructor(
    private dialogRef: MatDialogRef<DialogCargeMasivoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private authService: AuthService,
    private fechasService: FechasService,
    private commonService: CommonService,
    private snackBService: SnackBarService,
    private dialogService: DialogService,
    private personalService: PersonalService,
    private employerService: EmployerService
  ) {
    this.getUrlFormat();
    this.userAuth = this.authService.getUser();
    this.permissions = this.userAuth.claims;
    this.email = this.userAuth?.Email !== null && this.userAuth?.Email !== undefined ? this.userAuth?.Email : '';
    this.formFile = this.fb.group({
      File: '',
      Date: ''
    });
    this.module = this.data.module;
    // selected permisions
    this.permissionsModule = this.permissionsGestor.find(x => x.module === this.module);
    this.stateCurrent = this.data.docs;
    this.state = this.stateCurrent.State;

    this.commonService.getCommonOptions('PersonalContractStage').subscribe((data: CommonOptions[]) => {
      this.lastStages = data;
      if (this.state !== undefined) {
        const data = this.lastStages.find(x => x.Value === this.state);
        data !== undefined ? this.stateString = data.Description?.toLocaleUpperCase() : '';
      }
    });
    // Fecha state current
    if (this.data.docs.StateId !== null && this.data.docs.StateId !== '' && this.data.docs.StateId !== undefined) {
      this.formFile.get('Date').setValue(this.stateCurrent?.Date);
    }
  }

  getUrlFormat() {
    if (this.data.isFromPersonal === true) {
      this.urlFormat = 'https://analityco-acceso.s3.amazonaws.com/assets/files/Form+Agregar+Personal.xlsx';
    }else if (this.data.isFromEmployer === true) {
      this.urlFormat = 'https://analityco-acceso.s3.amazonaws.com/assets/documents/FormatoAgregarEmpresas.xlsx';
    }
  }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
    this.getInfoAdmin();
    this.loadDocuments();
  }

  getInfoAdmin() {
    this.commonService.getUserInfo().subscribe(userinfo => {
      this.permissionsCompany = userinfo.Companies.find(x => x.Id === this.data.adminInfoId).Permissions;
    });
  }

  getDateFormatR(date): string {
    let response = '';
    if (date !== null && date !== null) {
      response = this.fechasService.getDateFormatR(date);
    }
    return response;
  }

  getDateFormatRMinutes(date): string {
    let response: string;
    response = this.fechasService.getDateFormatRMinutes(date);
    return response;
  }

  openImgVideoUrl(url: any) {
    window.open(this.s3Bucket + url, '_blank');
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  // METODOS PARA CORTAR UNA IMAGEN Y GUARDARLA

  onSelect(event) {
    console.log('');
    this.files = [];
    const selectedFiles: FileList = event.addedFiles;
    for (let i = 0; i < selectedFiles.length; i++) {
      this.files.push(selectedFiles[i]);
    }
  }

  submitInitDocument(){
    const formData = new FormData();
    formData.append('File', this.files[0]);
    this.isProcessing = true;

    if (this.data.isFromPersonal === true) {
      this.personalService.putExtractFromDocument(this.data.adminInfoId, formData).subscribe((data) => {
        this.isProcessing = false;
        this.loadDocuments();
        this.files = [];
        this.snackBService.openSnackBar('Archivo subido correctamente.', 'X', 3000);

      }, (error: HttpErrorResponse) => {
        if (error.status === 420) {
          this.isProcessing = false;
          this.dialogService.openDialogGeneric('Lo sentimos, no se puede iniciar la actualizacion', 'se recomienda revisar el archivo cargado.', 'CANCELAR');
        }
      });
    }else if (this.data.isFromEmployer === true) {
      this.employerService.putExtractFromDocumentEmployer(this.data.adminInfoId, formData).subscribe((data) => {
        this.isProcessing = false;
        this.loadDocuments();
        this.files = [];
        this.snackBService.openSnackBar('Archivo subido correctamente.', 'X', 3000);
      }, (error: HttpErrorResponse) => {
        if (error.status === 420) {
          this.isProcessing = false;
          this.dialogService.openDialogGeneric('Lo sentimos, no se puede iniciar la actualizacion', 'se recomienda revisar el archivo cargado.', 'CANCELAR');
        }
      });
    }
  }

  removeFile() {
    this.files = [];
  }

  loadDocuments(){
    if (this.data.isFromPersonal === true) {
      this.personalService.getExtractFromDocument(this.data.adminInfoId).subscribe((data: any) => {
        this.stateCurrent = data;
        this.stories.push(data);
        this.storiesFiles = data.Files;
      });
    }else if (this.data.isFromEmployer === true) {
      this.employerService.getExtractFromDocumentEmployer(this.data.adminInfoId).subscribe((data: any) => {
        this.stateCurrent = data;
        this.stories.push(data);
        this.storiesFiles = data.Files;
      });
    }
  }
}
